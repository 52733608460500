import React from "react";
import { Routes, Route } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import Home from "../pages/home";
import About from "../pages/about";
import Projects from "../pages/projects";
import Project from "../pages/projects/detail";
import Contact from "../pages/contact";
import NotFound from "../pages/NotFound";
import { AnimatePresence } from "framer-motion";

export default function AppRoutes() {
	
	return (
		<AnimatePresence exitBeforeEnter>
			<Routes>
				<Route path="/" element={<AppLayout />}>
					<Route index element={<Home />} />
					<Route path="about" element={<About />} />
					<Route path="projects" element={<Projects />}/>
					<Route path="projects/:projectId" element={<Project />}/>
					<Route path="contact" element={<Contact />} />
					<Route path="*" element={<NotFound />} />
				</Route>
			</Routes>
		</AnimatePresence>
	)
}
