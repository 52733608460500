import React, { useContext} from 'react';
import ContactFooter from '../components/ContactFooter';
import { getPageDataApi, getWorkAreasApi } from '../api';
import { Localize } from '../contexts';
import { motion } from 'framer-motion';
import PageLoader from '../components/PageLoader/PageLoader';
import useSWR from 'swr';

export default function About() {
	const LocalizeContext = useContext(Localize);
	const page = 'about-page';
	
	function useData() {
		const { data:workAreas } = useSWR({param: 'work-areas', locale: LocalizeContext.locale}, getWorkAreasApi,{revalidateOnFocus: false});
		const { data:about } = useSWR({param: page, locale: LocalizeContext.locale}, getPageDataApi,{revalidateOnFocus: false});
		
		return {
			workAreas: workAreas,
      about: about,
      isLoading: !workAreas || !about,
    }
	}
	
	const { workAreas, about, isLoading } = useData();	

  return (
		isLoading ? <PageLoader /> :
		<motion.div key='aboutpage' initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{delayChildren: .3}} className='container'>
			<section className='about-hero'>
				<div>
					<motion.h1 initial={{y: '-50px', opacity:0}} animate={{y: '0px', opacity:1}} transition={{duration: .8}}>{about?.title}</motion.h1>
					<motion.p initial={{x: '-90px', opacity:0}} animate={{x: '0px', opacity:1}} transition={{duration: .5}}>
						{about?.description}
					</motion.p>
				</div>
			</section>
			<section className='about-section'>
				<img src='/img/raccoon-2.png' className='about-sect-raccoon' alt='' />
				<div>
					<h2>{about?.title2}</h2>
					<p>
						{about?.description2}
					</p>
				</div>
			</section>
			<section className='services-section'>
				<h2>Servicios</h2>
					<ul>
					{
						workAreas && workAreas.map(area =>
							<i className='badge' key={'badge-'+area?.id} style={{marginRight: 10,}}>{area?.name}</i>
						)
					}
					</ul>
			</section>
			{/* <div className="meet-us">
					<h2>El equipo <i className="fal fa-arrow-right"></i></h2>

					<div className="photos">
						<div className="BrandImage">
							<img src="/img/raccoon-2.jpg" alt="" className='photos-raccoon' />
						</div>
						<div className="team-designer">
							<div className="team-photo">
								<img src="https://via.placeholder.com/500" alt="" />
							</div>
							<div className="team-desc">
								<h4>Anderson Fariña</h4>
								<p>
									Diseñador senior enfocado al branding y la experiencia de usuario, su prioridad es crear cosas funcionales y hermosas.
								</p>
							</div>
						</div>
						<div className="team-developer">
							<div className="team-photo">
								<img src="https://via.placeholder.com/500" alt="" />
							</div>
							<div className="team-desc">
								<h4>Miguel Santamaria</h4>
								<p>
									Senior PHP developer, encargado de realizar los más complejos proyectos de desarrollo con la
									mejor tecnologia y buena onda.
								</p>
							</div>
						</div>
					</div>
				</div> */}

				<ContactFooter />
		</motion.div>
  )
}
