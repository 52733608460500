import React, {useContext, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Localize } from '../contexts';
import { getPageDataApi } from '../api';
import { motion } from 'framer-motion';
import useSWR from 'swr';

export default function Header() {
	const LocalizeContext = useContext(Localize);
	const [menuOpen, setMenuOpen] = useState(false);
	const changeLocale = lang => LocalizeContext.setLocale(lang);
	const { data:menuItems } = useSWR({param: 'navigation-menu', locale: LocalizeContext.locale}, getPageDataApi,{revalidateOnFocus: false});

	const toggleMenu = () => setMenuOpen(!menuOpen);
	return (
		<motion.div key='headnav' initial={{opacity: 0, x: '-30px'}} animate={{opacity: 1, x: '0px'}} exit={{opacity:0, x: '-30px'}} className='header'>
			<div className="container header-nav">
				<Link to={'/'} style={{zIndex: 99,}}>
					<img src={'/img/mapache-brand.svg'} alt="Mapache" className='brand' />
				</Link>
				<nav className='main-nav'>
					<Link to={'/projects'}>{menuItems?.title_1}</Link>
					<Link to={'/about'}>{menuItems?.title_2}</Link>
					<div className='dropdown'>
						<i className="fal fa-language"></i>
						<div className='dropdown-box'>
							<p className={'dropdown-item ' + (LocalizeContext.locale === 'es' ? 'active' : '')} onClick={() => changeLocale('es')}>Spanish</p>
							<p className={'dropdown-item ' + (LocalizeContext.locale === 'en' ? 'active' : '')} onClick={() => changeLocale('en')}>English</p>
						</div>
					</div>
				</nav>
				<nav className='mobile-nav'>
					<button type='button' className='mobile-nav__button' onClick={toggleMenu}>
						<i className='fal fa-bars'></i>
					</button>
					<div className={'mobile-nav__content' + (menuOpen ? ' active' : '')}>
						<div className='mobile-nav-items'>
							<Link to={'/projects'}>{menuItems?.title_1}</Link>
							<Link to={'/about'}>{menuItems?.title_2}</Link>
							<div className='dropdown'>
								<i className="fal fa-language"></i>
								<div className='dropdown-box'>
									<p className={'dropdown-item ' + (LocalizeContext.locale === 'es' ? 'active' : '')} onClick={() => changeLocale('es')}>Spanish</p>
									<p className={'dropdown-item ' + (LocalizeContext.locale === 'en' ? 'active' : '')} onClick={() => changeLocale('en')}>English</p>
								</div>
							</div>
						</div>
					</div>
				</nav>
			</div>
		</motion.div>
	)
}
