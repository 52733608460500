export const url = {
    apiUrl: 'https://admin.mapache.com.py',
    baseUrl: process.env.REACT_APP_BASE_URL,
}

export const appConfig = {
    bearer: process.env.REACT_APP_TOKEN,
}

export const apiHeaders = {
    headers:{
        Authorization: 'Bearer ' + appConfig.bearer,
    }
}