import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { url } from '../../config/config';
import './styles.css';

export default function ProyectsItem({works}) {
	const articleAnimate = {
		initial:{
			opacity: 0
		},
		animate:{
			opacity: 1,
		},
		exit:{
			opacity: 0
		},
		transition: {
			duration: .4,
			delayChildren: .5,
		}
	}
	const imageAnimate = {
		initial:{
			opacity: 0,
			x: '-40px'
		},
		animate:{
			opacity: 1,
			x: '0'
		},
		exit:{
			opacity: 0,
			x: '20px'
		},
		transition: {
			duration: .7,
		}
	}
	return (
		works && works.map(work =>
			<motion.article variants={articleAnimate} initial='initial' animate='animate' exit='exit' transition='transition' key={work?.id} className="work-item">
				<motion.div whileHover={{scale:1.02, rotate: 1,}} className="WorkImage">
					<motion.img variants={imageAnimate} initial='initial' animate='animate' exit='exit' transition='transition' src={url.apiUrl + work?.cover?.data?.url} alt="La duquesa branding" />
				</motion.div>
				<div className="WorkText">
					<h3>{work?.name}</h3>
					<p>{work?.work_area?.data?.name}</p>
					<p className="WorkText__desc">
						{work?.description}
					</p>
					<Link to={'/projects/'+work.id}>Ver detalles <i className="fal fa-arrow-right"></i></Link>
				</div>
			</motion.article>
		)
	)
}
