import React, {useContext, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Localize } from '../contexts';
import { getPageDataApi } from '../api';
import useSWR from 'swr';

export default function ContactFooter() {
	const LocalizeContext = useContext(Localize);
	const { data:footerData } = useSWR({param: 'contact-footer', locale: LocalizeContext.locale}, getPageDataApi,{revalidateOnFocus: false});

	return (
		<section id='contact' className="contact">
			<div>
				<h2>{footerData?.title}</h2>
				<Link to={'/contact'}>{footerData?.action} <i className="fal fa-arrow-right"></i></Link>
			</div>
			<div>
				<img src='/img/raccoon-3.png' alt='contact raccoon' className='contact-raccoon' />
			</div>

			<div className="ContactText">
				<a href='mailto:hola@mapache.com.py'>{footerData?.email}</a>
				<p>{footerData?.address}</p>
			</div>
			<div className="ContactBrand">
				<img src="/img/rac-white.svg" className="footer-brand" alt="Mapache logo" />
			</div>
		</section>
	)
}
