import React, { useContext } from 'react';
import ProyectsItem from '../components/ProyectsItem/ProyectsItem';
import ContactFooter from '../components/ContactFooter';
import { getWorksLimitedApi, getPageDataApi } from '../api';
import { Localize } from '../contexts';
import { motion } from 'framer-motion';
import PageLoader from '../components/PageLoader/PageLoader';
import SideServices from '../components/SideServices/SideServices';
import useSWR from 'swr';

export default function Home() {
	const LocalizeContext = useContext(Localize);
	const page = 'home-page';

	function useData(){
		const { data:pageData } = useSWR({param: page, locale: LocalizeContext.locale}, getPageDataApi,{revalidateOnFocus: false});
		const { data:works } = useSWR({qty: '5', locale: LocalizeContext.locale}, getWorksLimitedApi,{revalidateOnFocus: false});
		
		return {
			pageData: pageData,
			works: works,
			isLoading: !pageData || !works,
		}
	}

	const { pageData, works, isLoading } = useData();

	return (
		isLoading ? <PageLoader /> :
		<>
			<SideServices />
			<motion.div key='homepage' initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
				<section className="container">
					<div className="hero">
						<div className="HeroText">
							<motion.h1 initial={{ y: "-60px", opacity: 0, }} animate={{ y: "0px", opacity: 1 }} transition={{duration: .8, delay: .2}}>{pageData?.hero_title}</motion.h1>
						</div>
						<div className="HeroParagraph">
							<p>
								{pageData?.hero_paragraph}
							</p>
						</div>
						<div className="HeroActions">
							<a href={'#contact'}>{pageData?.action_1} <i className="fal fa-arrow-right"></i></a>
							<a href={"#works"}>{pageData?.action_2} <i className="fal fa-arrow-right"></i></a>
						</div>
						<img src='/img/raccoon-1.png' alt='raccoon' className='hero-raccoon' />
					</div>

					<div id='works' className="featured-works">
						<h2>{pageData?.works_title} <i className="fal fa-arrow-right"></i></h2>

						<div>
							{works &&
								<ProyectsItem works={works} />
							}
						</div>
					</div>

					<ContactFooter />
				</section>
			</motion.div>
		</>
	)
}
