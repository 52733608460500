import { motion } from 'framer-motion'
import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound() {
  return (
    <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} className='container'>
      <div style={{textAlign: 'center'}}>
        <img src='/img/raccoon-3.png' style={{width: '290px', margin: '1.3em auto 0', filter: 'grayscale(1)', mixBlendMode: 'soft-light',}}/>
        <h1 style={{marginBottom: 30,}}>Page not Found</h1>
        <Link className='btn-send' style={{marginBottom: '1.3em',}} to={'/'}>Go to home</Link>
      </div>
    </motion.div>
  )
}
