import React from 'react';
import { motion } from 'framer-motion';
import './style.css';

export default function PageLoader() {
  return (
    <motion.div key='pageloader' initial={{ top: -100 }} animate={{ top: 0 }} exit={{ top: -100 }} className="pre-loader">
      <img src='/img/rac-white.svg' style={{ width: '120px', }} alt='Mapache Logo' />
    </motion.div>
  )
}
