import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Localize } from "../contexts";
import Cursor from "../components/Cursor/Cursor";
import { Outlet } from 'react-router-dom';

export default function AppLayout() {
	const [locale, setLocale] = useState('es');
  
  return (
    <Localize.Provider value={{locale, setLocale}}>
      <Cursor />
      <Header />
      <>
          <Outlet />
      </>
      <Footer />
    </Localize.Provider>
  )
}
