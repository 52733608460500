import React, {useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom';
import { url } from '../../config/config';
import { getWorkApi } from '../../api';
import { Localize } from '../../contexts';
import { motion } from 'framer-motion';
import PageLoader from '../../components/PageLoader/PageLoader';
import useSWR, { useSWRConfig } from 'swr';

export default function Project() {
	let params = useParams();
	const LocalizeContext = useContext(Localize);
	// const { mutate } = useSWRConfig();
	const { data:work } = useSWR({id: params.projectId, locale: LocalizeContext.locale}, getWorkApi,{revalidateOnFocus: false,});

	useEffect(() => {
		window.scrollTo(0, 0);
		if (!work || work?.locale !== LocalizeContext.locale) {
			// setWork(data);
			// mutate({id: params.projectId, locale: LocalizeContext.locale});
		}
	}, []);

	// if (obtainData.isLoading === true) {
	// 	return <PageLoader />
	// } else {
		return (
			// loading ? <PageLoader /> :
				// work === null ? <PageLoader /> :
				<motion.div key='workpage' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delayChildren: .2, }}>
					<div className='container'>
						<Link to={'/projects'}><i className="fal fa-arrow-left"></i> Volver</Link>
						<div className='work-intro'>
							<motion.h1 initial={{ opacity: 0, y: '50px', }} animate={{ opacity: 1, y: '0px', }} transition={{ duration: .7 }}>{work?.name}</motion.h1>
							{
								work?.work_areas && work?.work_areas?.data.map(workarea =>
									<motion.i key={'workarea-' + workarea?.id} initial={{ opacity: 0, y: '30px', }} animate={{ opacity: 1, y: '0px', }} transition={{ duration: .7 }} className='badge'>{workarea?.name}</motion.i>
								)
							}
							<motion.p initial={{ opacity: 0, y: '50px', }} animate={{ opacity: 1, y: '0px', }} transition={{ duration: .5 }}>{work?.description}</motion.p>
						</div>
					</div>
					<motion.div initial={{ opacity: 0, filter: 'grayscale(1)', }} animate={{ opacity: 1, filter: 'grayscale(0)' }} exit={{ opacity: 0, filter: 'grayscale(0)' }} transition={{ duration: .8, delay: .2, }} className='project-hero-image' style={{ height: '90vh', width: '100%', background: `url(${url.apiUrl + work?.cover?.data?.url})`, }}></motion.div>
				</motion.div>
		)
	// }
}
