import axios from 'axios';
import { url, apiHeaders } from '../config/config';

export const getWorkAreasApi = async ({param, locale}) => {
	try {
		const res = await axios.get(`${url.apiUrl}/api/${param}?locale=${locale}`, apiHeaders);
		return res.data.data;
	} catch (error) {
		console.error(error);
		return error;
	}
}

export const getWorksApi = async (locale) => {
	try {
		const res = await axios.get(url.apiUrl + '/api/works?locale=' + locale + '&populate=cover,work_area', apiHeaders);
		return res.data.data;
	} catch (error) {
		console.error(error);
		return error;
	}
}

export const getWorkApi = async ({id, locale}) => {
	try {
		const res = await axios.get(url.apiUrl + '/api/works/' + id + '?locale=' + locale + '&populate=cover,images,work_areas', apiHeaders);
		return res.data.data;
	} catch (error) {
		console.error(error);
		return error;
	}
}

export const getWorksLimitedApi = async ({qty, locale}) => {
	try {
		const res = await axios.get(url.apiUrl + '/api/works?locale=' + locale + '&populate=cover&pagination[start]=0&pagination[limit]=' + qty, apiHeaders);
		return res.data.data;
	} catch (error) {
		console.error(error);
		return error;
	}
}

export const getPageDataApi = async ({param, locale}) => {
	try {
		const res = await axios.get(url.apiUrl + '/api/' + param + '?locale=' + locale, apiHeaders);
		return res.data.data;
	} catch (error) {
		console.error(error);
		return error;
	}
}