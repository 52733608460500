import React, { useContext } from 'react';
import { url } from '../../config/config';
import { Link } from 'react-router-dom';
import { getWorksApi, getWorkAreasApi } from '../../api';
import { Localize } from '../../contexts';
import { motion } from 'framer-motion';
import useSWR from 'swr';

export default function Projects() {
	// const [loading, setLoading] = useState(true);
	const LocalizeContext = useContext(Localize);
	const { data:works } = useSWR(LocalizeContext.locale, getWorksApi,{revalidateOnFocus: false});
	const { data:workAreas } = useSWR({param: 'work-areas', locale: LocalizeContext.locale}, getWorkAreasApi,{revalidateOnFocus: false});

	const buttonGroup = {
		visible: {
			opacity: 1,
		},
		hidden: {
			opacity: 0,
		}
	}

	const buttonGroupItem = {
		visible: i => ({
			y: 0,
			opacity: 1,
			transition: {
				delay: i * 0.03,
				duration: .4,
			}
		}),
		hidden: {
			y: 100,
			opacity: 0
		}
	}

	return (
		// loading ? <PageLoader /> :
		<motion.div key='workspage' initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} className='container'>
			<motion.div key='buttongroup' variants={buttonGroup} initial='hidden' animate='visible' className='btn-group'>
				<motion.button key='mainbutton' variants={buttonGroupItem} initial='hidden' animate='visible' className='btn-filter active'>Todos</motion.button>
				{
					workAreas && workAreas.map(area =>
						<motion.button variants={buttonGroupItem} initial='hidden' custom={area?.id} animate='visible' key={area?.id} className='btn-filter'>{area?.name}</motion.button>
					)
				}
			</motion.div>
			{
				works && works.map(work =>
					<Link to={'/projects/' + work.id} key={'work'+work?.id} className={'project-list show'}>
						<figure className='project-list__image'>
							<img src={url.apiUrl + work?.cover?.data?.url} alt={work?.name} />
						</figure>
						<figcaption className='project-list__title'>
							<h4>{work?.name}</h4>
						</figcaption>
					</Link>
				)
			}
		</motion.div>
	)
}
