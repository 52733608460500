import React, { useEffect, useContext, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { motion } from 'framer-motion';
import { getPageDataApi } from '../api';
import PageLoader from '../components/PageLoader/PageLoader';
import { Localize } from '../contexts';
import useSWR from 'swr';



export default function Contact() {
  const LocalizeContext = useContext(Localize);
  const page = 'contact-page';
  const form = useRef();

  
  function useData() {  
    const { data } = useSWR({param: page, locale: LocalizeContext.locale}, getPageDataApi,{revalidateOnFocus: false});
  
    return {
      pageData: data,
      isLoading: !data,
    }
  }

  const { pageData, isLoading } = useData();

  const submitForm = (e) => {
    e.preventDefault();

    const serviceID = 'service_owk6r7o';
    const templateID = 'template_l4yr8m8';
    const emailPubKey = 'v6SIh6tNu_Fk83ZTu';
    const sendButton = document.getElementById('sendBtn');
    const alert = document.getElementById('success-alert');
    sendButton.innerHTML = 'Sending...';
    alert.classList.add('show');

    emailjs.sendForm(serviceID, templateID, form.current, emailPubKey)
    .then((result) => {
      console.log(result.text);
      sendButton.innerHTML = pageData?.form_action;
      document.getElementById("contact-form").reset();
      setTimeout(() => {
        alert.classList.remove('show');
      }, 4000);
    }, (error) => {
      console.error(error.text);
    })
    
  }

	useEffect(() => {
    window.scrollTo(0, 0);
	}, []);

  return (
    isLoading ? <PageLoader /> :
    <>
      <div id='success-alert'>
        <h4>¡Mensaje enviado!</h4>
        <p>Apreciamos su interes en trabajar con nosotros, nos pondremos en contacto en la brevedad posible.</p>
      </div>
        
      <motion.div key='contactpage' initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} className='container'>
        <h1 className='contact-title'>{pageData?.title}</h1>
        
        <form ref={form} id='contact-form' onSubmit={submitForm}>
          <div className='form-group'>
            <label htmlFor='from_name'>{pageData?.form_name}</label>
            <input className='form-control' id='from_name' name='from_name' type='text' placeholder='Nombre' required />
          </div>
          <div className='form-group'>
            <label htmlFor='from_company'>{pageData?.form_company}</label>
            <input className='form-control' id='from_company' name='from_company' type='text' placeholder='Empresa' required />
          </div>
          <div className='form-group'>
            <label htmlFor='from_email'>Email</label>
            <input className='form-control' id='from_email' name='from_email' type='email' placeholder='Email' required />
          </div>
          <div className='form-group'>
            <label htmlFor='project'>Proyecto</label>
            <input className='form-control' id='from_project' name='from_project' type='text' placeholder='Proyecto' required />
          </div>
          <div className='form-group'>
            <label htmlFor='message'>{pageData?.form_message}</label>
            <textarea className='form-control' id='message' name='message' type='text' placeholder='Mensaje' required rows={5} />
          </div>
          <div className='form-group'>
            <button id='sendBtn' className='btn-send' type='submit' onClick={(e) => submitForm(e)}>{pageData?.form_action}</button>
          </div>
        </form>
      </motion.div>
    </>
  )
}
