import React, {useContext} from 'react';
import { motion } from 'framer-motion';
import { Localize } from '../../contexts';
import './styles.css';
import { getWorkAreasApi } from '../../api';
import useSWR from 'swr';

export default function SideServices() {
	const LocalizeContext = useContext(Localize);
	const { data:workAreas } = useSWR({param: 'work-areas', locale: LocalizeContext.locale}, getWorkAreasApi,{revalidateOnFocus: false});

	const sideShow = {
		hidden: {
			opacity: 0,
			x: '65px',
		},
		display: {
			opacity:1,
			x: '0px',
		},
		transition: { 
			duration: 3,
			delayChildren: 2
		}
	}
	return (
		<motion.div key='sideservices' variants={sideShow} initial='hidden' animate='display' transition='transition' exit='hiden' className='side-service-container'>
			<motion.h2 initial={{y: '0px', rotateZ: '90deg'}} animate={{y: '-50vh', rotateZ:'90deg'}} transition={{ease: 'linear', duration: 12, repeat: Infinity, repeatType: 'reverse',}} className='side-service-text'>
				{
					workAreas && workAreas.map((workArea) => 
						<span key={workArea?.id}>{workArea?.name}, </span>
					)
				}
			</motion.h2>
		</motion.div>
	)
}
