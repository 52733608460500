import React from 'react'

export default function Footer() {
  return (
		<footer>
			<div className="container">
				<p>© 2022 Mapache, Todos los derechos reservados.</p>
			</div>
		</footer>
  )
}
